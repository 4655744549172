export const environment = {
  production: true,
  appVersion: 'v726demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://your-domain.com/api',
  api_activos:'https://mo0fqo56ac.execute-api.us-east-1.amazonaws.com/prd/activos',
  api_mantenimientos: 'https://fuqdiw5xuf.execute-api.us-east-1.amazonaws.com/prd/mantenimientos',
  api_areas: 'https://sihrj6u7v1.execute-api.us-east-1.amazonaws.com/prd/Areas',
  api_cargos :'https://f2n41a19wl.execute-api.us-east-1.amazonaws.com/prd/cargos/',
  api_generales:'https://asor1tcnua.execute-api.us-east-1.amazonaws.com/prd/general',
  api_terceros:'https://nxq82u0ftf.execute-api.us-east-1.amazonaws.com/prd/tercero',
  api_modulos: 'https://7l05hvdauc.execute-api.us-east-1.amazonaws.com/prd/modulos',
  api_sedes: 'https://rd1kknc3kl.execute-api.us-east-1.amazonaws.com/prd/Offices',
  api_ubicaciones: 'https://imt68929k0.execute-api.us-east-1.amazonaws.com/prd/Locations',
  api_Assets: 'https://6el8miq666.execute-api.us-east-1.amazonaws.com/prd/Assets',
  api_internos: 'https://pzgzysdlo9.execute-api.us-east-1.amazonaws.com/prd/usuarios',
  api_usuarios:'https://pzgzysdlo9.execute-api.us-east-1.amazonaws.com/prd/usuarios',
  api_login: 'https://3wteboxpe3.execute-api.us-east-1.amazonaws.com/prd',
  api_notificaciones:'https://16yunzbnh4.execute-api.us-east-1.amazonaws.com/prd/notificaciones',
  api_roles : 'https://0xlgy6rkh4.execute-api.us-east-1.amazonaws.com/prd/roles',
  api_inve: 'https://3n4w6ey7tg.execute-api.us-east-1.amazonaws.com/prd/inventario',
  api_perfomace: 'https://mcalg11ssa.execute-api.us-east-1.amazonaws.com/prd/perfomace',
  api_Activos_Revisados : ' https://mcalg11ssa.execute-api.us-east-1.amazonaws.com/prd/activos-revisados',
  api_mtto_grupo_activo: 'https://mcalg11ssa.execute-api.us-east-1.amazonaws.com/prd/mtto-grupo-activo',
  api_realizacion_mtto : 'https://mcalg11ssa.execute-api.us-east-1.amazonaws.com/prd/realizacion-mtto',
  api_rendimiento_y_cumplimiento :'https://mcalg11ssa.execute-api.us-east-1.amazonaws.com/prd/compliance',
  api_contratos: 'https://6iywh23wo4.execute-api.us-east-1.amazonaws.com/prd/contratos'
};

// export const environment = {
//   production: false,
//   appVersion: 'v726demo1',
//   USERDATA_KEY: 'authf649fc9a5f55',
//   isMockEnabled: true,
//   // apiUrl: 'https://your-domain.com/api',
//   apiUrl: 'http://localhost:4200',
//   api_activos:'https://jdjha84e8b.execute-api.us-east-1.amazonaws.com/dev/activos',
//   api_terceros:'https://r8qimbq4rg.execute-api.us-east-1.amazonaws.com/dev/tercero',
//   api_generales:'https://0hd2fs5gd1.execute-api.us-east-1.amazonaws.com/dev/general',
//   api_modulos: 'https://u5zo5t41o6.execute-api.us-east-1.amazonaws.com/dev/modulos',
//   api_areas: 'https://8vnffdm9qf.execute-api.us-east-1.amazonaws.com/dev/Areas',
//   api_sedes: 'https://s282jelux0.execute-api.us-east-1.amazonaws.com/dev/Offices',
//   api_ubicaciones: 'https://ods2wdx4n7.execute-api.us-east-1.amazonaws.com/dev/Locations',
//   api_Assets: 'https://9p611oqzf8.execute-api.us-east-1.amazonaws.com/dev/Assets',
//   api_mantenimientos: 'https://c6h4qt8if2.execute-api.us-east-1.amazonaws.com/dev/mantenimientos',
//   api_internos: 'https://u4ensbgywc.execute-api.us-east-1.amazonaws.com/dev/usuarios',
//   api_usuarios:'https://u4ensbgywc.execute-api.us-east-1.amazonaws.com/dev/usuarios',
//   api_login: 'https://b9yvtpl6a9.execute-api.us-east-1.amazonaws.com/dev',
//   api_notificaciones:'https://plkz39q20a.execute-api.us-east-1.amazonaws.com/dev/notificaciones',
//   api_cargos :'https://7ydtg07uob.execute-api.us-east-1.amazonaws.com/dev/cargos',
//   api_roles : 'https://r5cb9kxwh8.execute-api.us-east-1.amazonaws.com/dev/roles',
//   api_inve: 'https://j8wmsofhag.execute-api.us-east-1.amazonaws.com/dev/inventario',
//   api_perfomace: 'https://feaxomhwul.execute-api.us-east-1.amazonaws.com/dev/perfomace',
//   api_Activos_Revisados : ' https://feaxomhwul.execute-api.us-east-1.amazonaws.com/dev/activos-revisados',
//   api_mtto_grupo_activo: 'https://feaxomhwul.execute-api.us-east-1.amazonaws.com/dev/mtto-grupo-activo',
//   api_realizacion_mtto : 'https://feaxomhwul.execute-api.us-east-1.amazonaws.com/dev/realizacion-mtto',
//   api_rendimiento_y_cumplimiento :'https://feaxomhwul.execute-api.us-east-1.amazonaws.com/dev/compliance'
// };
​
​
// export const environment = {
//   production: false,
//   appVersion: 'v726demo1',
//   USERDATA_KEY: 'authf649fc9a5f55',
//   isMockEnabled: true,
//   // apiUrl: 'https://your-domain.com/api',
//   apiUrl: 'http://localhost:4200',
//   api_activos:'https://jdjha84e8b.execute-api.us-east-1.amazonaws.com/dev/activos',
//   api_terceros:'https://r8qimbq4rg.execute-api.us-east-1.amazonaws.com/dev/tercero',
//   api_generales:'https://0hd2fs5gd1.execute-api.us-east-1.amazonaws.com/dev/general',
//   api_modulos: 'https://u5zo5t41o6.execute-api.us-east-1.amazonaws.com/dev/modulos',
//   api_areas: 'https://8vnffdm9qf.execute-api.us-east-1.amazonaws.com/dev/Areas',
//   api_sedes: 'https://s282jelux0.execute-api.us-east-1.amazonaws.com/dev/Offices',
//   api_ubicaciones: 'https://ods2wdx4n7.execute-api.us-east-1.amazonaws.com/dev/Locations',
//   api_Assets: 'https://9p611oqzf8.execute-api.us-east-1.amazonaws.com/dev/Assets',
//   api_mantenimientos: 'https://c6h4qt8if2.execute-api.us-east-1.amazonaws.com/dev/mantenimientos',
//   api_internos: 'https://u4ensbgywc.execute-api.us-east-1.amazonaws.com/dev/usuarios',
//   api_usuarios:'https://u4ensbgywc.execute-api.us-east-1.amazonaws.com/dev/usuarios',
//   api_login: 'https://b9yvtpl6a9.execute-api.us-east-1.amazonaws.com/dev',
//   api_notificaciones:'https://plkz39q20a.execute-api.us-east-1.amazonaws.com/dev/notificaciones',
//   api_cargos :'https://7ydtg07uob.execute-api.us-east-1.amazonaws.com/dev/cargos',
//   api_roles : 'https://r5cb9kxwh8.execute-api.us-east-1.amazonaws.com/dev/roles',
//   api_inve: 'https://j8wmsofhag.execute-api.us-east-1.amazonaws.com/dev/inventario',
//   api_perfomace: 'https://feaxomhwul.execute-api.us-east-1.amazonaws.com/dev/perfomace',
//   api_Activos_Revisados : ' https://feaxomhwul.execute-api.us-east-1.amazonaws.com/dev/activos-revisados',
//   api_mtto_grupo_activo: 'https://feaxomhwul.execute-api.us-east-1.amazonaws.com/dev/mtto-grupo-activo',
//   api_realizacion_mtto : 'https://feaxomhwul.execute-api.us-east-1.amazonaws.com/dev/realizacion-mtto',
//   api_rendimiento_y_cumplimiento :'https://feaxomhwul.execute-api.us-east-1.amazonaws.com/dev/compliance'
// };
​