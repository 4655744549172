import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { Param } from "../core/interfaces/Param";

@Injectable({ providedIn: "root" })
export class HttpBaseService {
  /**
   * Propiedad que especifica el dominio base del consumo de las webApi
   */
  private environment = environment.api_activos;

  /**
   * Inyeccion de dependencias para utilizar metodos http
   * @param _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Metodo GET
   * @param url
   * @param params
   * @returns
   */
  public getMethod<T>(url: string, httpParams: Param[] = []): Observable<T> {
    let params = new HttpParams();
    let headers = new HttpHeaders();
    const authorization: string | null = localStorage.getItem('Authorization'); 
    headers.set('Authorization', authorization)
    httpParams.forEach(
      (param) => (params = params.append(param.campo, param.valor))
    );
    
    return this._http.get<T>(url, { params:params, headers:headers });
  }

  /**
   * Metodo POST
   * @param url
   * @param params
   * @returns
   */
  public postMethod<T>(url: string, params: any = null): Observable<T> {
    return this._http.post<T>(url, params);
  }

  /**
   * Metodo PUT
   * @param url
   * @param params
   * @returns
   */
  public putMethod<T>(url: string, httpParams: any): Observable<T> {
    // let params = new HttpParams();
    // httpParams.forEach(param => params = params.append(param.campo, param.valor));
    return this._http.put<T>(url, httpParams);
  }

  /**
   * Metodo PUT
   * @param url
   * @param params
   * @returns
   */
  public deleteMethod<T>(url: string, httpParams: Param[] = []): Observable<T> {
    let params = new HttpParams();
    httpParams.forEach(
      (param) => (params = params.append(param.campo, param.valor))
    );

    return this._http.delete<T>(url, { params });
  }
  public deleteWithBody<T>(url: string, body: any): Observable<T> {
    let headers: HttpHeaders = new HttpHeaders();
    let httpOptions = {
      headers: headers,
      body: body,
    };
    return this._http.delete<T>(url, httpOptions);
  }
}
