import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpBaseService } from '../http-base.services';
let urlBase = environment.api_login;

@Injectable({
  providedIn: 'root'
})
export class AccesosService {

  constructor(private httpBase: HttpBaseService) { }

  getCompanies(idUser){
    const url = `${urlBase}/login/get-companies/${idUser}`;
    return this.httpBase.getMethod(url);
  }

  getFuncional(idFuncional){
    const url = `${urlBase}/login/get-funcional/${idFuncional}`;
    return this.httpBase.getMethod(url);
  }

}
