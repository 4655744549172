import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccesosService } from 'src/app/services/accesos/accesos.service';
import { DynamicAsideMenuConfig } from '../../configs/dynamic-aside-menu.config';

const emptyMenuConfig = {
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class DynamicAsideMenuService {

  menuLateral:any = [];

  private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
  menuConfig$: Observable<any>;
  constructor(private accesosService: AccesosService) {
    this.menuConfig$ = this.menuConfigSubject.asObservable();
    this.loadMenu();
  }

  // Here you able to load your menu from server/data-base/localStorage
  // Default => from DynamicAsideMenuConfig
  private loadMenu() {
    const usuario = JSON.parse(localStorage.getItem('usuario'))
    let suport = {
      proyecto: 'ACTIVO FIJO',
      idusuario: usuario.id,
      rcc: '122',
      nom: usuario.complete_name,
      tipdoc: 'CC',
      numdoc: '',
      email: usuario.email_user,
      tel: usuario.cellphone
    };
    localStorage.setItem('suport', JSON.stringify(suport))
    if(usuario.id_funcional){
      this.accesosService.getFuncional(usuario.id_funcional)
        .subscribe((res:any)=>{
          if(res.status){
              res.data.forEach(i => {
                let submenus = []
                  i.paginas.forEach(j => {
                    submenus.push({
                      title: j.namea,
                      page: j.pageurl
                    })
                  });

                let menu = {
                  title: i.namea,
                  svg: i.icon,
                  icon:'flaticon2-architecture-and-city',
                  submenu: submenus
                }
                this.menuLateral.push(menu)
              
            });
            let result ={
              items:this.menuLateral
            }
            // Menú de servicio
            this.setMenu(result);
            
            // Menu quemado
            // this.setMenu(DynamicAsideMenuConfig);

          }else{
            this.setMenu(DynamicAsideMenuConfig);
          }
          
      },error=>{
        this.setMenu(DynamicAsideMenuConfig);
      })
    }else{
      // console.log('No tiene funcional');
    }

   
  }

  private setMenu(menuConfig) {
    this.menuConfigSubject.next(menuConfig);
  }

  private getMenu(): any {
    return this.menuConfigSubject.value;
  }
}
