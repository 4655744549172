import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
import { HttpBaseService } from '../../../../services/http-base.services';


const API_USERS_URL = `${environment.apiUrl}/auth`;
const ApiLogin = environment.api_login;
@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient,
    private httpBase: HttpBaseService) { }

    
  // public methods
  login(user: string, password: string): Observable<any> {
    const url = `${ApiLogin}/login`;
    return this.httpBase.postMethod(url,{ user, password });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    const url = `${ApiLogin}/login`;
    return this.httpBase.postMethod(url,{ email, 'forgotPassword':true });    
  }

  getUserByToken(token): Observable<UserModel> {
    const user = JSON.parse(
      localStorage.getItem('usuario')
    );
    if (user.authToken === token) {
      return of(user);
    }
    return of(undefined);
    
  }
}
